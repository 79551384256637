<template>
  <mcs-chip-select
    v-model="computedValue"
    :items="roles"
    :disabled="disabled"
    :label="collection_role(computedValue).name"
    :color="collection_role(computedValue).color"
  />
</template>

<script>
import { ENUMS } from '@/utils/constants';
import { mapGetters } from 'vuex';
export default {
  props: { value: { type: Number, default: 0 } },
  data() {
    return {};
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    disabled() {
      return this.computedValue === ENUMS.COLLECTION_ROLES.OWNER;
    },
    roles() {
      return this.collection_roles.map((x) =>
        Object.assign(x, { disabled: x.id === ENUMS.COLLECTION_ROLES.OWNER })
      );
    },
    ...mapGetters({
      collection_roles: 'lookups/collection_roles',
      collection_role: 'lookups/collection_role'
    })
  }
};
</script>
