<template>
  <Drawer
    :id="drawerId"
    :title="title"
  >
    <div class="pa-4">
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-text-field
          v-model="item.name"
          label="Name"
          outlined
          :rules="[$rules.required]"
          maxlength="100"
          class="mb-4"
          hide-details="auto"
        />
      </v-form>
      <mcs-btn
        large
        :loading="saving"
        color="black"
        block
        @click="save"
      >
        Save
      </mcs-btn>
    </div>
  </Drawer>
</template>

<script>
import Drawer from '@/components/layout/Drawer';
import { DRAWERS } from '@/utils/constants';
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
export default {
  components: { Drawer },
  data: () => ({
    drawerId: DRAWERS.RENAME,
    saving: false,
    valid: false,
    item: {},
    saveAction: '',
    title: ''
  }),
  computed: {
    ...mapGetters({
      initialData: 'drawers/data',
      top_drawer: 'drawers/topDrawer',
      drawerOpenedAt: 'drawers/lastOpenedAt'
    })
  },

  watch: {
    drawerOpenedAt() {
      if (this.drawerId === this.top_drawer?.id) {
        this.tab = 0;
        const initial = this.initialData(this.drawerId) || {};
        this.item = cloneDeep(initial.item);
        this.title = initial.title;
        this.saveAction = initial.saveAction;
      }
    }
  },
  methods: {
    cancel() {
      this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
    },
    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        this.$store
          .dispatch(this.saveAction, this.item)
          .then((data) => {
            this.$refs.form.reset();
            this.cancel();
          })
          .catch((error) => {
            this.logError(error, `An error occurred while renaming a ${this.title.toLowerCase()}.`);
          })
          .finally(() => {
            this.saving = false;
          });
      }
    }
  }
};
</script>
