<template>
  <v-menu
    close-on-content-click
    transition="scale-transition"
    offset-y
    max-width="420px"
    min-width="420px"
    max-height="400px"
    :disabled="disabled"
  >
    <template #activator="{ on, attrs }">
      <mcs-chip
        :color="color"
        v-bind="attrs"
        v-on="on"
      >
        <div :class="['my-auto pl-2', disabled ? 'pr-2' : '']">
          {{ label }}
        </div>
        <v-icon
          v-if="!disabled"
          class="mr-1"
        >
          mdi-menu-down
        </v-icon>
      </mcs-chip>
    </template>
    <v-list>
      <v-list-item-group v-model="computedValue">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :value="item[itemValue]"
          :class="dense ? '' : 'py-3 px-7'"
          :disabled="item.disabled"
          @click="menu = false"
        >
          <v-list-item-action
            v-if="item.icon"
            class="mr-5"
          >
            <v-avatar
              size="36"
              :color="item.disabled ? 'grey' : item.color"
            >
              <v-icon color="white">
                {{ item.icon }}
              </v-icon>
            </v-avatar>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item[itemText] }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="item.description"
              class="normal-wrap"
            >
              {{ item.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: { type: Number, default: 0 },
    label: { type: String, default: '' },
    color: { type: String, default: 'black' },
    disabled: { type: Boolean, default: false },
    items: { type: Array, default: () => {} },
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'name' },
    dense: { type: Boolean, default: false }
  },
  data() {
    return {};
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(val) {
        if (val) {
          this.$emit('input', val);
        }
      }
    }
  }
};
</script>
