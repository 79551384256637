import { ENUMS, ROUTES } from '../constants';
import { getLocal, setLocal } from '../local';
export default {
  methods: {
    setLocal(key, val) {
      setLocal(key, val);
    },
    getLocal(key, val) {
      return getLocal(key, val);
    },

    getCampaignIdeasLink(id) {
      if (!id) {
        id = ENUMS.SNAILBLAST.CAMPAIGN_TYPE.LIST_PURCHASE;
      }
      return ROUTES.CAMPAIGN_IDEAS.PATH.replace(':id', id);
    },
    getOrdersLink(type) {
      if (!type) {
        type = 'all';
      }
      return ROUTES.ADMIN_ORDERS.PATH.replace(':orderType', type);
    },
    setBrandColor() {
      var primaryColor = this.$store.getters['user/master_account'].primaryColor;
      console.log('primaryColor', primaryColor);
      if (primaryColor && this.$portalMode()) {
        this.$vuetify.theme.themes.light.brand = primaryColor;
      }
    }
  }
};
