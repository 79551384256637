import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default {
  methods: {
    $loadGa() {
      Vue.use(VueGtag, {
        config: { id: this.$store.getters['config/analytics'].gtagId }
      });
    }
  }
};
