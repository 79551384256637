<template>
  <v-container
    v-if="render"
    class="px-8 my-3"
  >
    <div class="rounded-xl overflow-hidden">
      <v-img
        src="https://res.cloudinary.com/mycreativeshop/image/upload/f_auto/v1735830594/public/MissionPrint-15off_Jan2025_2"
      />
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'PromoBanner',

  data() {
    return {};
  },

  computed: {
    render() {
      return this.$store.getters['user/isMissionPrint'];
    }
  },

  methods: {}
};
</script>

<style scoped></style>
