
export default {
  SNAILBLAST: {
    CAMPAIGN_TYPE: {
      EDDM: 1,
      UPLOAD: 2,
      LIST_PURCHASE: 3
    },
    MAP_STYLES: {
      STREETS: 'mapbox://styles/mapbox/streets-v12',
      SATELLITES: 'mapbox://styles/mapbox/satellite-v9'
    },
    LIST_PURCHASE_DATABASE: {
      BUSINESS: 1,
      NEW_BUSINESS: 2,
      CONSUMER: 3,
      NEW_MOVER: 4,
      NEW_HOMEOWNER: 5
    },
    LIST_PURCHASE_FIELD_TYPES: {
      TEXT: 1,
      SELECT: 2,
      MONTH_DATE: 3,
      BOOL: 4,
      YEAR_RANGE: 5,
      DROPDOWN: 6
    }
  },
  UNITS: { INCHES: 1, MILLIMETERS: 2, PIXELS: 4 },
  BLEED: {
    INCHES: 0.125,
    MILLIMETERS: 3.175
  },
  LOCAL: {
    HIDE_ALERT: 'hide-[type]-alert'
  },
  ASSET_TYPES: {
    TEMPLATES: 'designs',
    COLORS: 'colors',
    IMAGES: 'images',
    PEOPLE: 'people'
  },
  SUBSCRIPTION_INTERVAL: { MONTH: 1, YEAR: 2 },
  SUBSCRIPTION_STATUS: {
    ACTIVE: 1,
    TRIAL: 2,
    PAST_DUE: 3,
    CANCELLED: 4,
    UNPAID: 5,
    NOT_CREATED: 6
  },
  ROLES: {
    ADMIN: 'admin',
    MASTER_ACCOUNT_OWNER: 'master_account_owner',
    DESIGNER: 'designer',
    CONTENT_ADMIN: 'content_admin',
    ASSET_CONTRIBUTOR: 'asset_contributor',
    IMAGE_CONTRIBUTOR: 'image_contributor'
  },
  COLLECTION_ROLES: { OWNER: 1, ADMIN: 2, DESIGNER: 3, MEMBER: 4 }
};
