<template>
  <mcs-sheet :class="[color]">
    <div class="font-weight-bold">
      <v-icon
        small
        class="mr-1"
      >
        {{ icon }}
      </v-icon>
      {{ title }}
    </div>
    <div>
      {{ text }}
    </div>
    <div
      v-if="$slots.actions"
      class="pt-4 d-flex"
    >
      <slot name="actions"></slot>
    </div>
  </mcs-sheet>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    color: { type: String, default: 'greyLight' },
    icon: { type: String, default: 'mdi-information' }
  }
};
</script>
