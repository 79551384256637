<template>
  <Drawer
    :id="drawerId"
    :title="title"
  >
    <div class="px-4 pt-4">
      <mcs-inline-nav
        v-if="views.length > 1"
        v-model="view"
        class="mb-4"
        :items="views"
      />
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row v-if="showPresets">
          <v-col cols="12">
            <v-select
              v-model="categoryId"
              outlined
              :items="categories"
              label="Category"
              placeholder="Select a category"
              validate-on-blur
              item-value="id"
              item-text="name"
              :rules="[$rules.required]"
              hide-details="auto"
              @change="changeCategory"
            />
          </v-col>
          <template v-if="categoryId">
            <v-col
              v-if="showUnits"
              cols="12"
            >
              <v-select
                v-model="unitId"
                outlined
                label="Units"
                class="pt-0 mt-0"
                :items="units"
                item-value="id"
                item-text="name"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12">
              <v-radio-group
                v-model="coreProductId"
                :rules="[$rules.required]"
                class="py-0 my-0"
                hide-details="auto"
              >
                <v-radio
                  v-for="(item, i) in filtered_core_products"
                  :key="i"
                  color="black"
                  :label="item.name"
                  :value="item.id"
                >
                </v-radio>
              </v-radio-group>
            </v-col>
          </template>
        </v-row>
        <template v-else-if="showCustom">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="unitId"
                outlined
                label="Units"
                class="min-width pt-0 mt-0"
                :items="units"
                item-value="id"
                item-text="name"
                hide-details="auto"
                :rules="[$rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="width"
                outlined
                label="Width"
                validate-on-blur
                hide-details="auto"
                :rules="[$rules.required, $rules.double]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="height"
                outlined
                label="Height"
                validate-on-blur
                hide-details="auto"
                :rules="[$rules.required, $rules.double]"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="showBleed"
                outlined
                :items="bleedItems"
                label="Include bleed"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row v-if="showBleed">
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="top"
                outlined
                validate-on-blur
                label="Top"
                hide-details="auto"
                :rules="[$rules.double]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="left"
                label="Left"
                validate-on-blur
                outlined
                hide-details="auto"
                :rules="[$rules.double]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="bottom"
                outlined
                validate-on-blur
                hide-details="auto"
                label="Bottom"
                :rules="[$rules.double]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="right"
                outlined
                label="Right"
                validate-on-blur
                hide-details="auto"
                :rules="[$rules.double]"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="pages"
                label="# of pages"
                :rules="[$rules.required]"
                outlined
              />
            </v-col>
          </v-row>
        </template>
      </v-form>
    </div>
    <Portal :to="`drawerAppend-${drawerId}`">
      <div class="pa-4">
        <mcs-btn
          large
          color="black"
          block
          :loading="saving"
          @click="save"
        >
          {{ saveText }}
        </mcs-btn>
      </div>
    </Portal>
  </Drawer>
</template>

<script>
import Drawer from '@/components/layout/Drawer';
import { DRAWERS, ENUMS } from '@/utils/constants';
import collections from '@/utils/mixins/collections';
import { uniq } from 'lodash';
import { mapGetters } from 'vuex';
export default {
  components: { Drawer },
  mixins: [collections],
  props: {},
  data: () => ({
    saving: false,
    valid: false,
    drawerId: DRAWERS.BLANK_CANVAS,
    isTemplate: false,
    categoryId: null,
    coreProductId: null,
    width: null,
    height: null,
    unitId: null,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    name: '',
    showBleed: true,
    pages: 2,
    view: 'presets',
    bleedItems: [
      { text: 'Yes', value: true },
      { text: 'No', value: false }
    ]
  }),
  computed: {
    saveText() {
      return this.isTemplate ? 'Create Template' : 'Create project';
    },
    views() {
      const list = [
        { title: 'Presets', id: 'presets', hide: !this.categories.length },
        { title: 'Custom', id: 'custom' }
      ];
      return list.filter((x) => !x.hide);
    },
    isCurrent() {
      return (view) => {
        return view === this.view;
      };
    },
    title() {
      return 'New design';
    },
    showCustom() {
      return this.view === 'custom';
    },
    showPresets() {
      return this.view === 'presets';
    },
    showUnits() {
      if (this.showCustom) {
        return true;
      }
      if (this.core_products.length) {
        const hasMultipleUnits = uniq(this.core_products.map((x) => x.unitId)).length > 1;
        if (hasMultipleUnits) {
          return true;
        }
      }
      return false;
    },
    core_products() {
      return this.core_products_func(this.categoryId);
    },
    filtered_core_products() {
      let list = this.core_products;
      if (this.unitId && this.showUnits) {
        list = list.filter((x) => x.unitId === this.unitId);
      }
      return list;
    },
    category() {
      return this.categories.find((x) => x.id === this.categoryId);
    },
    ...mapGetters({
      folders: 'admin/collections/all_folders',
      initialData: 'drawers/data',
      top_drawer: 'drawers/topDrawer',
      drawerOpenedAt: 'drawers/lastOpenedAt',
      categories: 'templates/categories',
      core_products_func: 'templates/core_products',
      units: 'lookups/units',
      prefersMetric: 'user/prefersMetric'
    })
  },
  watch: {
    drawerOpenedAt(val, oldVal) {
      if (this.drawerId === this.top_drawer?.id) {
        this.unitId = this.prefersMetric ? ENUMS.UNITS.MILLIMETERS : ENUMS.UNITS.INCHES;
        this.setBleed();
        this.saving = false;
        const initial = this.initialData(this.drawerId) || {};
        this.isTemplate = initial.isTemplate;
        this.view = this.views[0].id;
        this.categoryId = initial.categoryId || null;
      }
    },
    unitId(val, oldVal) {
      if (val !== oldVal) {
        this.setBleed();
      }
    },
    categoryId(val, oldVal) {
      if (val !== oldVal) {
        this.coreProductId = null;
      }
    }
  },
  methods: {
    cancel() {
      this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
    },
    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        this.$api
          .post('collections/getTemplateEditorUrl', {
            isCustom: this.showCustom,
            collectionId: this.isTemplate ? this.collectionId : null,
            folderId: this.parentId,
            coreProductId: this.coreProductId,
            unitId: this.unitId,
            height: this.height,
            width: this.width,
            top: this.top,
            bottom: this.bottom,
            left: this.left,
            right: this.right,
            pages: this.pages
          })
          .then((response) => {
            window.open(response.data.url, '_blank');
            this.cancel();
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
    setBleed() {
      switch (this.unitId) {
        case ENUMS.UNITS.INCHES:
          this.top = ENUMS.BLEED.INCHES;
          this.bottom = ENUMS.BLEED.INCHES;
          this.left = ENUMS.BLEED.INCHES;
          this.right = ENUMS.BLEED.INCHES;
          break;
        case ENUMS.UNITS.MILLIMETERS:
          this.top = ENUMS.BLEED.MILLIMETERS;
          this.bottom = ENUMS.BLEED.MILLIMETERS;
          this.left = ENUMS.BLEED.MILLIMETERS;
          this.right = ENUMS.BLEED.MILLIMETERS;
          break;
        default:
          this.top = 0;
          this.bottom = 0;
          this.left = 0;
          this.right = 0;
          break;
      }
    },
    changeCategory() {
      this.coreProductId = null;
    }
  }
};
</script>
