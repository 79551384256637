<template>
  <mcs-sheet
    no-padding
    class="border-rounded-none"
  >
    <div class="d-flex">
      <v-sheet class="greyLight pa-2 my-auto">
        <v-img
          :src="item.project.previewUrl"
          width="130"
          contain
        />
      </v-sheet>
      <div class="pl-4">
        <div class="body-1 font-weight-bold">
          {{ projectName || 'Unnamed project' }}
        </div>
        <div class="body-2 mt-2">
          <div>
            Qty {{ (item.campaign ? item.campaign.recipientCount : item.quantity) | separator }}
          </div>
          <div>{{ item.project.coreProductName }}</div>
          <div>
            {{
              material(item.materialId)
                .names.filter((x) => x.categoryId == item.project.categoryId)
                .map((x) => x.text)[0] || material(item.materialId).display
            }}
          </div>
          <template v-if="item.addressId">
            <div class="font-weight-bold mt-2">Ship to:</div>
            <div>
              {{ address(item.addressId).firstName }} {{ address(item.addressId).lastName }}
            </div>
            <div v-if="address(item.addressId).company">
              {{ address(item.addressId).company }}
            </div>
            <div>
              {{ address(item.addressId).line1 }} {{ address(item.addressId).city }}
              {{ address(item.addressId).state }}, {{ address(item.addressId).postalCode }}
            </div>
          </template>
          <div
            v-if="item.campaign"
            class="mt-2"
          >
            <div class="font-weight-bold">Print & mail to:</div>
            <div>{{ item.campaign.recipientCount | separator }} addresses</div>
          </div>
          <div
            v-if="item.delivery"
            class="mt-2"
          >
            <template v-if="item.campaign">
              <div class="font-weight-bold">Expected In-Mailboxes:</div>
              <div v-if="item.delivery.valid">
                {{ scheduledRange }}
              </div>
              <mcs-chip
                v-else
                color="accentLight"
                append-icon="mdi-alert-circle"
                @click="$emit('edit', item)"
              >
                Change mailbox date
              </mcs-chip>
            </template>
            <template v-else>
              <div class="font-weight-bold">Estimated Delivery:</div>
              <div v-if="item.delivery.valid">
                {{ item.delivery.earliestDate | regularDateNoYear }} -
                {{ item.delivery.latestDate | regularDateNoYear }}
              </div>
              <mcs-chip
                v-else
                color="accentLight"
                append-icon="mdi-alert-circle"
                @click="$emit('edit-shipping')"
              >
                Change delivery date
              </mcs-chip>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-4">
      <mcs-chip
        color="white"
        append-icon="mdi-pencil"
        class="my-auto mr-2"
        @click="$emit('edit')"
      >
        Edit Order
      </mcs-chip>
      <mcs-chip
        class="my-auto"
        color="white"
        append-icon="mdi-close"
        :loading="deletingItemId === item.id"
        @click="deleteItem(item)"
      >
        Remove
      </mcs-chip>
      <div class="my-auto ml-auto body-1">
        <div class="d-flex align-center font-weight-bold">
          <div
            v-if="item.subTotalPriceOriginal > item.subTotalPrice"
            class="text-decoration-line-through mr-2"
          >
            {{ item.subTotalPriceOriginal | currency }}
          </div>
          <div :class="{ 'success--text': item.subTotalPriceOriginal > item.subTotalPrice }">
            {{ item.subTotalPrice | currency }}
          </div>
          <mcs-btn
            v-if="allowCosts"
            icon
            color="black"
            prepend-icon="mdi-eye-outline"
            class="ml-2"
            @click="showCosts = !showCosts"
          ></mcs-btn>
        </div>
      </div>
    </div>
    <v-expand-transition v-if="allowCosts">
      <v-sheet
        v-show="showCosts"
        outlined
        class="mt-0 px-4 body-2 mb-3"
        dense
      >
        <div class="d-flex justify-space-between w-full my-2">
          <div><b>Print:</b> {{ item.baseCost | currency }}</div>
          <div v-if="item.delivery.turnaroundCost">
            <b>Turnaround:</b> {{ item.delivery.turnaroundCost | currency }}
          </div>
          <div v-if="item.postageCost"><b>Postage:</b> {{ item.postageCost | currency }}</div>
          <div v-if="item.listPurchaseCost">
            <b>List Purchase:</b> {{ item.listPurchaseCost | currency }}
          </div>
          <div v-if="item.delivery.shippingCost">
            <b>Shipping:</b> {{ item.delivery.shippingCost | currency }}
          </div>
          <div v-if="item.delivery.salesTaxCost">
            <b>Taxes:</b> {{ item.delivery.salesTaxCost | currency }}
          </div>
          <div>
            <b>Profit:</b>
            {{
              (item.subTotalPrice -
                item.baseCost -
                item.delivery.turnaroundCost -
                item.delivery.shippingCost -
                item.delivery.salesTaxCost -
                item.postageCost -
                item.listPurchaseCost)
                | currency
            }}
          </div>
        </div>
      </v-sheet>
    </v-expand-transition>
  </mcs-sheet>
</template>

<script>
import snailblast from '@/utils/mixins/snailblast';
import { mapGetters } from 'vuex';

export default {
  name: 'PrintCart',

  components: {},

  mixins: [snailblast],

  props: {
    item: { type: Object, default: () => {} }
  },

  data: () => ({
    deletingItemId: 0,
    showCosts: false
  }),

  computed: {
    address() {
      return (addressId) => {
        return this.addresses.find((x) => x.id === addressId) || {};
      };
    },
    allowCosts() {
      return this.isAdmin || !!this.runas;
    },
    scheduledRange() {
      return this.getScheduleRangeFormatted(this.item.delivery.earliestDate);
    },
    projectName() {
      return this.item?.project.name?.replace('snapshot of ', '');
    },

    ...mapGetters({
      cart: 'printcart/cart',
      material: 'lookups/material',
      addresses: 'user/addresses',
      project: 'projects/project',
      isAdmin: 'user/isAdmin',
      runas: 'config/runas'
    })
  },

  methods: {
    deleteItem(item) {
      this.deletingItemId = item.id;
      this.$store
        .dispatch('printcart/deleteItem', this.item.id)
        .then(() => {
          this.$emit('deleted');
        })
        .catch((error) => {
          this.$(error);
          this.$root.$alert('Error Occurred', 'An error occured while removing an item.');
        })
        .finally(() => {
          this.deletingItemId = 0;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
