const state = {
  item: {},
  allPlans: [],
  currentPlanDiscount: null,
  reactivationPromoCode: null,
  selectedPlan: null,
  invoicePreviewLoading: false,
  invoicePreview: null,
  teamAdminEmail: null,
  teamUserCount: 0
};

const getters = {
  currencies(state) {
    return state.allPlans?.map((x) => x.currency) || [];
  },

  currentCurrencyCode(state, getters) {
    // stripe currency always overrides - it's the currency set on the stripe customer and it can't ever be changed
    if (state.stripeCurrency) return state.stripeCurrency;
    if (!state.selectedPlan) return 'USD';
    return state.selectedPlan.currency.code;
  },

  plansForCurrency(state, getters) {
    return state.allPlans.find((x) => x.currency.code === getters.currentCurrencyCode);
  },

  enableMultiCurrency(state, getters, rootState) {
    if (getters.currencies.length <= 1 || state.stripeCurrency) return false;
    if (rootState.config.inDevEnv) return true;
    if (rootState.user.profile.country.isInternational && getters.plansForCurrency?.length) {
      return true;
    }
    return false;
  },

  monthlyPlan(state, getters) {
    return getters.plansForCurrency?.monthlyPlan || {};
  },

  annualPlan(state, getters) {
    return getters.plansForCurrency?.annualPlan || {};
  },

  allowPlanToggle(state, getters) {
    if (getters.annualPlan.stripePlanId && !state.item.lifetimeDiscountApplied) {
      return true;
    }
    return false;
  },

  selectedPlan(state, getters) {
    if (state.selectedPlan) return state.selectedPlan || {};
    return getters.monthlyPlan;
  },

  selectedPlanInterval(state, getters) {
    return getters.selectedPlan.isMonthly ? 'Monthly' : 'Annually';
  },

  selectedPlanIntervalPer(state, getters) {
    return `per ${getters.selectedPlan.interval?.toLowerCase()}`;
  },

  selectedPlanBasePriceFormatted(state, getters) {
    return getters.selectedPlan.costFormatted;
  },

  enableFirstMonthDiscount(state, getters, rootState, rootGetters) {
    return (
      !rootGetters['user/hasOrdered'] &&
      getters.selectedPlan.isMonthly &&
      getters.selectedPlan.firstMonthPromoCodeId
    );
  },

  enableTeams(state, getters) {
    return getters.selectedPlan.perUserStripePriceId != null;
  },

  teamsPricePerUserFormatted(state, getters) {
    return getters.selectedPlan.perUserPriceFormatted;
  },

  annualSavings(state, getters) {
    var obj = { amount: 0, percent: 0, monthlyCost: 0 };
    if (getters.selectedPlan.isAnnual) {
      var planGroup = state.allPlans.find(
        (x) => x.currency.code === getters.selectedPlan.currency?.code
      );
      var monthlyPlan = planGroup.monthlyPlan;
      var annualCost = monthlyPlan.cost * 12;
      obj.amount = parseFloat(annualCost - getters.selectedPlan.cost).toFixed(2);
      obj.percent = (parseFloat(obj.amount / annualCost) * 100).toFixed(0);
      obj.monthlyCost = getters.selectedPlan.monthlyCost;
    }
    return obj;
  },

  totalDue(state, getters) {
    if (getters.enableFirstMonthDiscount) return getters.selectedPlan.firstMonthCost;
    if (state.upgradeInvoice) return state.upgradeInvoice.total;
    var price = getters.selectedPlan.cost;
    if (state.teamUserCount && getters.selectedPlan.perUserStripePriceId) {
      price += state.teamUserCount * getters.selectedPlan.perUserPrice;
    }
    return price;
  },

  totalDueInSubunit(state, getters) {
    return Math.round(getters.totalDue * 100);
  },

  totalDueFormatted(state, getters, rootState, rootGetters) {
    if (getters.enableFirstMonthDiscount) return getters.selectedPlan.firstMonthCostFormatted;
    if (state.upgradeInvoice) return state.upgradeInvoice.totalFormatted;
    return `${rootGetters['user/plan'].currencySymbol}${getters.totalDue.toFixed(2)}`;
  }
};

const actions = {
  getDetail({ commit, rootState }) {
    console.log('getting subscription detail');
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('subscription/detail')
        .then((response) => {
          commit('SET_ITEM', response.data);
          commit('cards/SET_CARDS', response.data.cards, { root: true });
          commit('user/UPDATE_PLAN', response.data.userPlan, { root: true });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  cancel({ commit, rootState }, reasonId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`subscription/cancel?reasonId=${reasonId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  reactivate({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('subscription/reactivate', payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  previewInvoice({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      var payload = {
        subscriptionId: state.item.subscriptionId,
        stripePlanId: state.selectedPlan.stripePlanId
      };
      commit('SET_INVOICE_PREVIEW_LOADING', true);
      this._vm.$api
        .post('subscription/previewInvoice', payload)
        .then((response) => {
          commit('SET_INVOICE_PREVIEW', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit('SET_INVOICE_PREVIEW_LOADING', false);
        });
    });
  },

  changePlan({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('subscription/changePlan', payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  },

  retryPayment({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('subscription/retryPayment', payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setDefaultPayment({ commit, rootState }, cardId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`subscription/setDefaultPayment?cardId=${cardId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_ITEM(state, payload) {
    state.item = payload.subscription || {};
    state.allPlans = payload.allPlans || [];
    state.currentPlanDiscount = payload.currentPlanDiscount;
    state.reactivationPromoCode = payload.reactivationPromoCode;
    state.stripeCurrency = payload.stripeCurrency?.toUpperCase();
    state.teamAdminEmails = payload.teamAdminEmails || [];
    state.teamUserCount = payload.teamUserCount;
  },

  SET_SELECTED_PLAN(state, plan) {
    state.selectedPlan = plan;
  },

  SET_INVOICE_PREVIEW(state, invoice) {
    state.invoicePreview = invoice;
    state.invoicePreviewLoading = false;
  },

  SET_INVOICE_PREVIEW_LOADING(state, loading) {
    state.invoicePreviewLoading = loading;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
