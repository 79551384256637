import { ENUMS, ROUTES } from '../constants';
export default {
  computed: {
    parentId() {
      let val = this.$route.params.parentId;
      if (val && val.length > 0) {
        val = parseInt(val);
      }
      return val || null;
    },
    collectionId() {
      let val = this.$route.params.collectionId;
      if (val && val.length > 0) {
        val = parseInt(val);
      }

      return val || null;
    },
    links() {
      return [
        {
          title: 'Designs',
          path: this.getCollectionsDetailPath(ENUMS.ASSET_TYPES.TEMPLATES)
        },
        {
          title: 'Images',
          path: this.getCollectionsDetailPath(ENUMS.ASSET_TYPES.IMAGES)
        },
        {
          title: 'Colors',
          path: this.getCollectionsDetailPath(ENUMS.ASSET_TYPES.COLORS)
        }
      ];
    }
  },
  methods: {
    getPublishedColor(published, hex) {
      if (published) {
        return 'successLight';
      }
      return 'accentLight';
    },
    getCollectionsDetailPath(type, parentId, collectionId) {
      if (!collectionId) {
        collectionId = this.collectionId;
      }
      let link = ROUTES.ADMIN_TEAM_DETAIL.PATH;
      if (type) {
        link = ROUTES.ADMIN_TEAM_DETAIL_TYPE.PATH;
      }
      if (parentId) {
        link = ROUTES.ADMIN_TEAM_DETAIL_TYPE_CHILD.PATH;
      }

      return link.replace(':typeId', type).replace(':collectionId', collectionId).replace(':parentId', parentId);
    }
  }
};
