import { cloneDeep } from 'lodash';

const state = {
  templates: [],
  folders: [],
  template: {},
  edit_template_url: ''
};

const getters = {
  all_templates(state) {
    return state.templates || [];
  },
  templates(state, getters) {
    return (id) => {
      return getters.all_templates.filter((x) => x.folderId === id);
    };
  },
  all_folders(state) {
    return state.folders || [];
  },
  folders(state, getters) {
    return (id) => {
      return getters.all_folders
        .filter((x) => x.parentId === id)
        .map((x) =>
          Object.assign(x, {
            folderCount: getters.all_folders.filter((y) => y.parentId === x.id).length,
            templateCount: getters.all_templates.filter((y) => y.folderId === x.id).length
          })
        );
    };
  },
  parents(state) {
    return (id) => {
      const parents = [];
      let current = state.folders.find((x) => x.id === id);
      if (current) {
        do {
          parents.splice(0, 0, current);
          current = state.folders.find((x) => x.id === current.parentId);
        } while (current);
      }
      return parents;
    };
  },
  template(state) {
    return state.template || {};
  },
  edit_template_url(state) {
    return state.edit_template_url || '';
  }
};

const mutations = {
  SET_TEMPLATES(state, item) {
    state.templates = item.templates;
    state.folders = item.folders;
  },
  SET_TEMPLATE(state, item) {
    state.template = cloneDeep(item);
  },
  SET_EDIT_TEMPLATE_URL(state, url) {
    state.edit_template_url = url;
  }
};

const actions = {
  async getTemplates({ commit }, collectionId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`userCollections/getTemplates?collectionId=${collectionId}`)
        .then((response) => {
          commit('SET_TEMPLATES', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getTemplate({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`userCollections/getTemplate?id=${id}`)
        .then((response) => {
          commit('SET_TEMPLATE', response.data.template);
          commit('SET_EDIT_TEMPLATE_URL', response.data.edit_url);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
