<template>
  <div>
    <mcs-btn
      id="newDesignButton"
      color="black"
      outlined
      class="mr-3 px-4 bg-white"
      prependIcon="mdi-plus"
      v-on="items.length == 1 && items[0].onClick !== null ? { click: items[0].onClick } : {}"
    >
      New Design
    </mcs-btn>
    <v-menu
      v-if="items.length > 1"
      v-model="menu"
      activator="#newDesignButton"
      close-on-content-click
      transition="scale-transition"
      offset-y
      right
      max-width="420px"
      max-height="400px"
      content-class="rounded-lg"
    >
      <v-list
        outlined
        class="rounded-lg"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          class="py-3 px-7"
          v-on="item.onClick ? { click: item.onClick } : {}"
        >
          <v-list-item-action class="mr-5">
            <v-avatar
              size="36"
              :color="item.color"
            >
              <v-icon :color="item.iconColor">
                {{ item.icon }}
              </v-icon>
            </v-avatar>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { DRAWERS, ROUTES } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'NewDesignButton',

  data() {
    return { position_x: 0, position_y: 0, menu: false };
  },

  computed: {
    items() {
      const list = [
        {
          name: 'Team designs',
          description: 'Start from one of your team designs',
          icon: 'mdi-account-group',
          iconColor: 'success',
          color: 'successLight',
          to: ROUTES.TEAM_PROJECTS.PATH,
          hide: !this.hasCollectionTemplates
        },
        {
          name: 'Find a template',
          description: 'Start from one of our design templates',
          icon: 'mdi-magnify',
          iconColor: 'primary',
          color: 'primaryLight',
          onClick: this.openCatalogDrawer,
          hide: !this.enableMcsTemplates && !this.collections_to_manage.length
        },
        {
          name: 'Blank canvas',
          description: 'Select your size and design from scratch',
          icon: 'mdi-ruler',
          iconColor: 'black',
          color: 'greyLight',
          onClick: this.showBlankCanvas
        }
      ];
      return list.filter((x) => !x.hide);
    },

    ...mapGetters({
      hasCollectionTemplates: 'user/hasCollectionTemplates',
      enableMcsTemplates: 'user/enableMcsTemplates',
      collections_to_manage: 'user/collections_to_manage'
    })
  },

  methods: {
    openCatalogDrawer() {
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.CATALOG_DRAWER);
    },
    showMenu(event) {
      this.menu = true;
      this.position_x = event.clientX;
      this.position_y = event.clientY;
    },
    showBlankCanvas() {
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.BLANK_CANVAS);
    }
  }
};
</script>

<style></style>
