import Vue from 'vue';
import Vuex from 'vuex';
import designgroups from './modules/admin/designgroups';
import cards from './modules/cards';
import config from './modules/config';
import contributorAssetProjects from './modules/contributorAssetProjects';
import contributorDesignProjects from './modules/contributorDesignProjects';
import contributorImageProjects from './modules/contributorImageProjects';
import drawers from './modules/drawers';
import lookups from './modules/lookups';
import orders from './modules/orders';
import printcart from './modules/printcart';
import projects from './modules/projects';
import subscription from './modules/subscription';
import teams from './modules/teams';
import templates from './modules/templates';
import user from './modules/user';
import userCollections from './modules/userCollections';
// contributor stores
import contributorCustomerProjects from './modules/contributor/customerProjects';
// admin stores
import attributes from './modules/admin/attributes';
import collections from './modules/admin/collections';
import adminOrders from './modules/admin/orders';
import productSetup from './modules/admin/productSetup';
import users from './modules/admin/users';
// snailblast stores
import campaign from './modules/snailblast/campaign';
import sbLookup from './modules/snailblast/lookup';
import routes from './modules/snailblast/routes';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    admin: {
      namespaced: true,
      modules: {
        attributes: attributes,
        collections: collections,
        users: users,
        designgroups: designgroups,
        productSetup: productSetup,
        orders: adminOrders
      }
    },
    contributor: {
      namespaced: true,
      modules: {
        customerProjects: contributorCustomerProjects
      }
    },
    snailblast: {
      namespaced: true,
      modules: {
        routes: routes,
        campaign: campaign,
        lookup: sbLookup
      }
    },
    cards,
    config,
    contributorAssetProjects,
    contributorDesignProjects,
    contributorImageProjects,
    drawers,
    lookups,
    orders,
    printcart,
    projects,
    subscription,
    templates,
    teams,
    user,
    userCollections
  }
});
