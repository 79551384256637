<template>
  <div>
    <v-app class="mcs">
      <PageLoadingProgress />
      <template v-if="!appLoading">
        <CollectionMultiAppBar />
        <AlertBar app />

        <Nav v-if="!iframed" />
        <Header v-if="!iframed" />

        <v-main
          class="greyLight"
          :style="{ 'margin-top': selected.length ? '-64px' : 'inherit' }"
        >
          <PromoBanner />
          <v-container class="h-full pt-6 px-8">
            <router-view :key="routeKey" />
          </v-container>
          <Footer v-if="!iframed" />
          <Snackbar ref="snackbar" />
        </v-main>
        <SubscriptionCheckoutDrawer v-if="!iframed" />
        <ReactivateSubscriptionDrawer />
        <CancelSubscriptionDrawer />
        <DefaultPaymentMethodDrawer />
        <AddCardDrawer />
        <RetryPaymentDrawer />
        <PrintCheckoutDrawer v-if="!iframed" />
        <ShippingDrawer />
        <SnailblastAudienceDrawer />
        <SnailblastUploadAddressesEditDrawer />
        <UpdateCardDrawer />
        <PrintPreviewDrawer />
        <ReviewDrawer />
        <ProofOverlay />
        <ZoomImageOverlay />
        <CatalogDrawer />
        <EditorDrawer />
        <NonProfitDrawer />
        <ExemptionCertificatesDrawer />
        <PrintSelectProjectDrawer />
        <ManageAddressesDrawer />
        <AddPeopleDrawer />
        <ForcePassword />
        <RenameDrawer />
        <BlankCanvasDrawer />
      </template>
    </v-app>
    <GenericAlert ref="alert" />
    <GenericConfirm ref="confirm" />
    <Clipboard ref="clipboard" />
  </div>
</template>

<script>
import CollectionMultiAppBar from '@/components/collections/admin/MultiAppBar';
import AddPeopleDrawer from '@/components/people/Add';
import ManageAddressesDrawer from '@/components/print/checkout/addresses/ManageDrawer';
import ForcePassword from '@/components/profile/ForcePassword';
import BlankCanvasDrawer from '@/components/projects/BlankCanvasDrawer';
import { mapGetters } from 'vuex';
import Clipboard from './components/alerts/Clipboard';
import GenericAlert from './components/alerts/GenericAlert';
import GenericConfirm from './components/alerts/GenericConfirm';
import Snackbar from './components/alerts/Snackbar';
import CatalogDrawer from './components/catalog/CatalogDrawer';
import EditorDrawer from './components/editor/EditorDrawer';
import AlertBar from './components/layout/AlertBar';
import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import Nav from './components/layout/Nav';
import AddCardDrawer from './components/payments/AddCardDrawer';
import UpdateCardDrawer from './components/payments/UpdateCardDrawer';
import PrintPreviewDrawer from './components/print/PreviewDrawer';
import ProofOverlay from './components/print/ProofOverlay';
import PrintCheckoutDrawer from './components/print/checkout/PrintCheckoutDrawer';
import PrintSelectProjectDrawer from './components/print/checkout/SelectProjectDrawer';
import ShippingDrawer from './components/print/checkout/ShippingDrawer';
import SnailblastAudienceDrawer from './components/print/checkout/snailblast/audience/Drawer';
import SnailblastUploadAddressesEditDrawer from './components/print/checkout/snailblast/upload/addresses/EditDrawer';
import ExemptionCertificatesDrawer from './components/profile/nonprofit/ExemptionCertificatesDrawer';
import NonProfitDrawer from './components/profile/nonprofit/NonProfitDrawer';
import ReviewDrawer from './components/reviews/ReviewDrawer';
import CancelSubscriptionDrawer from './components/subscription/CancelDrawer';
import SubscriptionCheckoutDrawer from './components/subscription/CheckoutDrawer';
import DefaultPaymentMethodDrawer from './components/subscription/DefaultPaymentMethodDrawer';
import ReactivateSubscriptionDrawer from './components/subscription/RectivateDrawer';
import RetryPaymentDrawer from './components/subscription/RetryPaymentDrawer';
import PageLoadingProgress from './components/utilities/PageLoadingProgress';
import PromoBanner from './components/utilities/PromoBanner.vue';
import RenameDrawer from './components/utilities/RenameDrawer';
import ZoomImageOverlay from './components/utilities/ZoomImageOverlay';
import customerio from './plugins/customerio';
import googleAnalytics from './plugins/google-analytics';
import helpscout from './plugins/helpscout';
import hubspot from './plugins/hubspot';
import { ANALYTICS_EVENTS, APP_EVENTS, DRAWERS, ROUTES } from './utils/constants';
import postMessageHandler from './utils/postMessageHandler';
import setFavicon from './utils/setFavicon';

export default {
  name: 'App',

  components: {
    Nav,
    Header,
    PromoBanner,
    Footer,
    GenericAlert,
    GenericConfirm,
    Clipboard,
    Snackbar,
    AlertBar,
    PageLoadingProgress,
    SubscriptionCheckoutDrawer,
    ReactivateSubscriptionDrawer,
    CancelSubscriptionDrawer,
    RetryPaymentDrawer,
    DefaultPaymentMethodDrawer,
    PrintCheckoutDrawer,
    ShippingDrawer,
    SnailblastAudienceDrawer,
    SnailblastUploadAddressesEditDrawer,
    AddCardDrawer,
    UpdateCardDrawer,
    PrintPreviewDrawer,
    ReviewDrawer,
    ProofOverlay,
    ZoomImageOverlay,
    CatalogDrawer,
    EditorDrawer,
    NonProfitDrawer,
    ExemptionCertificatesDrawer,
    PrintSelectProjectDrawer,
    ManageAddressesDrawer,
    AddPeopleDrawer,
    CollectionMultiAppBar,
    ForcePassword,
    RenameDrawer,
    BlankCanvasDrawer
  },

  mixins: [helpscout, hubspot, customerio, postMessageHandler, googleAnalytics],

  data() {
    return {
      authTriggered: false,
      pageCount: 0
    };
  },

  computed: {
    routeKey() {
      return this.$store.state.config.routeKey;
    },

    appLoading() {
      return !this.$store.state.config.appDataLoaded;
    },

    route() {
      return this.$store.state.config.route;
    },

    iframed() {
      return this.$store.state.config.route.meta?.iframe;
    },

    ...mapGetters({
      authorized: 'user/authorized',
      topDrawer: 'drawers/topDrawer',
      selected: 'admin/collections/selected'
    })
  },

  watch: {
    route(val) {
      this.$mixpanel.trackPage();
      if (this.pageCount === 0) {
        if (this.route.query.drawer) {
          this.autoOpenDrawer(this.route.query.drawer);
        }
        this.pageCount++;
      }
    },

    topDrawer(val) {
      if (val?.id) {
        var drawerId = Object.keys(DRAWERS).find((x) => DRAWERS[x] === val.id);
        this.$mixpanel.trackEvent(ANALYTICS_EVENTS.DRAWER_OPENED, null, { id: drawerId });
        this.$nextTick(() => {
          document.documentElement.classList.add('overflow-hidden');
        });
      } else {
        document.documentElement.classList.remove('overflow-hidden');
      }
    }
  },

  async created() {
    console.log('CREATED -> App');
    this.$store
      .dispatch('user/authorize')
      .then(() => {
        if (this.$store.getters['user/isAdmin'] && this.$route.query.runas) {
          this.$store.dispatch('user/runas', this.$route.query.runas).then((result) => {
            window.history.pushState(null, '', window.location.pathname);
            if (result.error) {
              this.$root.$alert('Unable to Runas User', result.error, {
                cancelText: '',
                okText: 'Close'
              });
            } else {
              localStorage.setItem('runas', this.$route.query.runas);
              this.$router.go(ROUTES.DASHBOARD.PATH);
            }
          });
        } else {
          this.$store.dispatch('config/getAppData').then(() => {
            this.$mixpanel.peopleSetOnce(
              this.$store.state.user.profile.analyticsProfile,
              this.$store.state.user.userId
            );

            this.$loadCustomerIO();
            this.$loadHubspot();
            this.$loadGa();

            this.$store.commit('config/SET_APP_DATA_LOADED', true);
            setFavicon(this.$store.state.config.faviconUrl);
            this.$eventBus.$emit(APP_EVENTS.APP_DATA_READY);

            this.$api.get('data/getLazyAppData').then((response) => {
              this.$store.commit('user/INIT_LAZY_APP_DATA', response.data);
              this.$store.commit('teams/SET_TEAMS', response.data.teams);
              this.$store.commit('lookups/INITIALIZE', response.data.lookupData);
            });

            this.setBrandColor();
          });
        }
        this.bindPostMessageHandlers();
      })
      .catch((error) => {
        var trackError = true;
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 302) {
            trackError = false;
            window.location = error.response.data;
          } else if (error.response.status === 403) {
            trackError = false;
            window.location = `${process.env.VUE_APP_WEB_URL}account/login?returnUrl=${window.location.href}`;
          } else {
            this.$root.$alert('Authorization Error', error.message, { closeOnly: true });
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log('The request was made but no response was received');
          var msg =
            'Please check your network settings to make sure you have an active internet connection and then refresh this page.';
          if (this.$store.state.config.inDevEnv) {
            msg += '<br/><br/><strong>DEV: This is likely a CORS error</strong>';
          }
          this.$root.$alert('No Response Received', msg, { closeOnly: true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Unknown error - something happened in setting up the request');
          this.$root.$alert(
            'Unknown error',
            'An unknown error has occurrred. Please try again or contact support',
            { closeOnly: true }
          );
        }
        if (trackError) {
          throw new Error(error);
        }
      });
  },

  mounted() {
    // make our alerts & confirms globally accessible
    this.$nextTick(() => {
      this.$root.$alert = this.$refs.alert.open;
      this.$root.$confirm = this.$refs.confirm.open;
      this.$root.$copy = this.$refs.clipboard.copy;
    });
    this.$eventBus.$once(APP_EVENTS.SNACKBAR_MOUNTED, () => {
      this.$root.$snackbarSuccess = this.$refs.snackbar.openSnackbarSuccess;
      this.$root.$snackbarError = this.$refs.snackbar.openSnackbarError;
    });
    this.$eventBus.$on('refreshDataTableHeader', () => {
      this.$nextTick(() => {
        document
          .getElementsByClassName('v-data-table-header')[0]
          .getElementsByTagName('th')
          .forEach((x) => {
            x.style.width = x.offsetWidth + 30 + 'px';
          });
      });
    });
    if (this.$store.state.config.inDevEnv) {
      window.vm = this;
      // this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.CANCEL_SUBSCRIPTION_DRAWER);
      window.snackbarSuccess = () => {
        this.$root.$snackbarSuccess('Yay!');
      };
      window.snackbarError = () => {
        this.$root.$snackbarError('Uh oh!');
      };
    }
  },

  methods: {
    autoOpenDrawer(id) {
      if (id === 'nonprofit') {
      }
      var key = Object.keys(DRAWERS).find((key) => DRAWERS[key].toLowerCase() === id);
      if (key) this.$store.commit('drawers/OPEN_DRAWER', DRAWERS[key]);
    }
  }
};
</script>

<style scoped></style>
