import enums from './enums';

export const ROUTES = {
  DASHBOARD: {
    NAME: 'dashboard',
    DISPLAY: 'Dashboard',
    PATH: '/'
  },
  PROJECTS: {
    NAME: 'projects',
    DISPLAY: 'Projects',
    PATH: '/projects'
  },
  PROJECT_DETAIL: {
    NAME: 'project-detail',
    DISPLAY: 'Details',
    PATH: '/project/detail'
  },
  TEAM_PROJECTS: {
    NAME: 'team-projects',
    DISPLAY: 'Team designs',
    PATH: '/projects/team'
  },
  TEAM_PROJECTS_CHILD: {
    NAME: 'team-projects-child',
    DISPLAY: 'Team designs',
    PATH: '/projects/team/:parentId'
  },
  TEAM_PROJECT_DETAIL: {
    NAME: 'team-project-detail',
    DISPLAY: 'Details',
    PATH: '/projects/team/:id/detail'
  },
  PRINT: {
    NAME: 'print',
    DISPLAY: 'Printing',
    PATH: '/print'
  },
  SUBSCRIPTION: {
    NAME: 'subscription',
    DISPLAY: 'Subscription',
    PATH: '/subscription'
  },
  PRINT_CHECKOUT: {
    NAME: 'print-checkout',
    DISPLAY: 'Print Checkout',
    PATH: '/checkout/print'
  },
  ORDER_HISTORY: {
    NAME: 'order-history',
    DISPLAY: 'Order History',
    PATH: '/orders'
  },
  ORDER_DETAIL: {
    NAME: 'order-detail',
    DISPLAY: 'Order Detail',
    PATH: '/orders/detail'
  },
  CARDS: {
    NAME: 'cards',
    DISPLAY: 'Payment Methods',
    PATH: '/cards'
  },
  PROFILE: {
    NAME: 'profile',
    DISPLAY: 'Profile',
    PATH: '/profile'
  },
  CONTRIBUTOR_DESIGN_PROJECTS: {
    NAME: 'contributor-design-projects',
    DISPLAY: 'Projects',
    PATH: '/contributor/design-projects/:statusId',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.DESIGNER]
  },
  CONTRIBUTOR_ASSET_PROJECTS: {
    NAME: 'contributor-asset-projects',
    DISPLAY: 'Projects',
    PATH: '/contibutor/asset-projects/:statusId',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.ASSET_CONTRIBUTOR]
  },
  CONTRIBUTOR_IMAGE_PROJECTS: {
    NAME: 'contributor-image-projects',
    DISPLAY: 'Projects',
    PATH: '/contibutor/image-projects/:statusId',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.IMAGE_CONTRIBUTOR]
  },
  CONTRIBUTOR_CUSTOMER_PROJECTS: {
    NAME: 'contributor-customer-projects',
    DISPLAY: 'Projects',
    PATH: '/contributor/customer-projects/:statusId',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.DESIGNER]
  },
  CONTRIBUTOR_PAYOUTS: {
    NAME: 'contributor-payouts',
    DISPLAY: 'Payouts',
    PATH: '/contributor/payouts',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.DESIGNER, enums.ROLES.ASSET_CONTRIBUTOR, enums.ROLES.IMAGE_CONTRIBUTOR]
  },
  CAMPAIGN_IDEAS: {
    NAME: 'campaign-ideas',
    DISPLAY: 'Campaign Ideas',
    PATH: '/campaign-ideas/:id'
  },
  ADMIN_BANKING: {
    NAME: 'admin-banking',
    DISPLAY: 'Banking',
    PATH: '/admin/banking',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_SUBSCRIPTION: {
    NAME: 'admin-subscription',
    DISPLAY: 'Subscription',
    PATH: '/admin/subscription',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_SETTINGS: {
    NAME: 'admin-settings',
    DISPLAY: 'Settings',
    PATH: '/admin/settings',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_THEME: {
    NAME: 'admin-theme',
    DISPLAY: 'Theme',
    PATH: '/admin/theme',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_PRODUCT_SETUP: {
    NAME: 'admin-product-setup',
    DISPLAY: 'Products',
    PATH: '/admin/products',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_PEOPLE: {
    NAME: 'admin-people',
    DISPLAY: 'People',
    PATH: '/admin/people',
    AUTHORIZE_TEAM: [enums.COLLECTION_ROLES.OWNER]
  },
  ADMIN_ATTRIBUTES: {
    NAME: 'admin-custom-attributes',
    DISPLAY: 'Attributes',
    PATH: '/admin/people/attributes',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_TEAMS: {
    NAME: 'admin-teams',
    DISPLAY: 'Teams',
    PATH: '/teams',
    AUTHORIZE_TEAM: [enums.COLLECTION_ROLES.OWNER, enums.COLLECTION_ROLES.ADMIN, enums.COLLECTION_ROLES.DESIGNER]
  },
  ADMIN_TEAM_DETAIL: {
    NAME: 'admin-team-detail',
    DISPLAY: 'Team',
    PATH: '/teams/:collectionId',
    AUTHORIZE_TEAM: [enums.COLLECTION_ROLES.OWNER, enums.COLLECTION_ROLES.ADMIN, enums.COLLECTION_ROLES.DESIGNER]
  },
  ADMIN_TEAM_DETAIL_TYPE: {
    NAME: 'admin-team-detail-type',
    DISPLAY: 'Detail',
    PATH: '/teams/:collectionId/:typeId',
    AUTHORIZE_TEAM: [enums.COLLECTION_ROLES.OWNER, enums.COLLECTION_ROLES.ADMIN, enums.COLLECTION_ROLES.DESIGNER]
  },
  ADMIN_TEAM_DETAIL_TYPE_CHILD: {
    NAME: 'admin-team-detail-type-child',
    DISPLAY: 'Detail',
    PATH: '/teams/:collectionId/:typeId/:parentId',
    AUTHORIZE_TEAM: [enums.COLLECTION_ROLES.OWNER, enums.COLLECTION_ROLES.ADMIN, enums.COLLECTION_ROLES.DESIGNER]
  },
  ADMIN_SETUP_INDUSTRIES: {
    NAME: 'admin-product-setup-industries',
    DISPLAY: 'Industry',
    PATH: '/admin/product-setup/industries',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_SETUP_CATEGORY: {
    NAME: 'admin-product-setup-category',
    DISPLAY: 'Category',
    PATH: '/admin/product-setup/category/:categoryId',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_SETUP_COREPRODUCT: {
    NAME: 'admin-setup-coreproduct',
    DISPLAY: 'Print',
    PATH: '/admin/product-setup/category/:categoryId/products/:productId',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_SETUP_MATERIAL: {
    NAME: 'admin-setup-material',
    DISPLAY: 'Product',
    PATH: '/admin/product-setup/category/:categoryId/products/:productId/materials/:materialId',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_MASTER_ACCOUNTS: {
    NAME: 'admin-master-accounts',
    DISPLAY: 'Master Accounts',
    PATH: '/admin/master-accounts',
    AUTHORIZE: [enums.ROLES.ADMIN]
  },
  ADMIN_ORDERS_PAYOUTS: {
    NAME: 'admin-orders-payouts',
    DISPLAY: 'Payouts',
    PATH: '/admin/orders/payouts',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_ORDERS_PAYMENTS: {
    NAME: 'admin-orders-payments',
    DISPLAY: 'Stripe Payments',
    PATH: '/admin/orders/payments',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_DESIGN_FEED: {
    NAME: 'admin-design-feed',
    DISPLAY: 'Design Feed',
    PATH: '/admin/design-feed',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_EXEMPTION_CERTS: {
    NAME: 'admin-exemption-certs',
    DISPLAY: 'Exemption Certificates',
    PATH: '/admin/tax-exempt-certificates/:statusId',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_APP_LINKS: {
    NAME: 'admin-app-links',
    DISPLAY: 'App Links',
    PATH: '/admin/app-links',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_API_DOCS: {
    NAME: 'admin-api-docs',
    DISPLAY: 'Api Documentation',
    PATH: '/admin/api/:id',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  PRINT_CHECKOUT_IFRAME: {
    NAME: 'print-checkout-iframe',
    PATH: '/print-checkout-iframe'
  },
  SUBSCRIPTION_CHECKOUT_IFRAME: {
    NAME: 'subscription-checkout-iframe',
    PATH: '/subscription-checkout-iframe'
  },
  ADMIN_ORDERS: {
    NAME: 'admin-orders',
    DISPLAY: 'Admin Orders',
    PATH: '/admin/orders/:orderType',
    AUTHORIZE: [enums.ROLES.ADMIN, enums.ROLES.MASTER_ACCOUNT_OWNER]
  },
  ADMIN_STYLES: {
    NAME: 'admin-styles',
    DISPLAY: 'Stylesheet',
    PATH: '/admin/styles',
    AUTHORIZE: [enums.ROLES.ADMIN]
  },
  NOTFOUND: {
    NAME: 'not-found',
    PATH: '/not-found'
  }
};

export const COLORS = {
  // BRAND: '#4F46E5',  // ????
  WHITE: {
    NAME: 'white',
    HEX: '#FFFFFF'
  },
  BLACK: {
    NAME: 'black',
    HEX: '#000000'
  },
  PRIMARY: {
    NAME: 'primary',
    HEX: '#5E2DC1'
  },
  PRIMARY_LIGHT: {
    NAME: 'primaryLight',
    HEX: '#EAE2F8'
  },
  ACCENT: {
    NAME: 'accent',
    HEX: '#FF7903'
  },
  ACCENT_LIGHT: {
    NAME: 'accentLight',
    HEX: '#FFDECC'
  },
  GREY: {
    NAME: 'grey',
    HEX: '#E5E5E5'
  },
  GREY_LIGHT: {
    NAME: 'greyLight',
    HEX: '#F8F9F9'
  },
  SUCCESS: {
    NAME: 'success',
    HEX: '#16A34A'
  },
  SUCCESS_LIGHT: {
    NAME: 'successLight',
    HEX: '#DCFCE7'
  },
  ERROR: {
    NAME: 'error',
    HEX: '#F72C2F'
  },
  ERROR_LIGHT: {
    NAME: 'errorLight',
    HEX: '#FFCFD0'
  }
  // LIGHT: '#F8F8F8', // REMOVE
  // ERROR: '#FF5252', // REMOVE
  // SUCCESS: '#57BB89', // REMVOE
  // WARNING: '#FFE191', // REMOVE
  // TAN: '#F6E7C0', // REMOVE
  // GREEN: '#4CAA3A',  // REMOVE
  // ORANGE: '#FFA913', // REMOVE
  // BACKGROUND: '#F5F5F5' // REMOVE
};

export const DRAWERS = {
  PROFILE: 'profileDrawer',
  SUBSCRIPTION_CHECKOUT: 'subscriptionCheckoutDrawer',
  PRINT_CHECKOUT: 'printCheckoutDrawer',
  ADD_CARD_DRAWER: 'addCardDrawer',
  UPDATE_CARD_DRAWER: 'updateCardDrawer',
  CATALOG_DRAWER: 'catalogDrawer',
  CONTRIBUTOR_DESIGN_PROJECT_DRAWER: 'contributorDesignProjectDrawer',
  CONTRIBUTOR_ASSET_PROJECT_DRAWER: 'contributorAssetProjectDrawer',
  CONTRIBUTOR_IMAGE_PROJECT_DRAWER: 'contributorImageProjectDrawer',
  RETRY_PAYMENT_DRAWER: 'retryPaymentDrawer',
  REACTIVATE_SUBSCRIPTION_DRAWER: 'reactivateSubscriptionDrawer',
  CANCEL_SUBSCRIPTION_DRAWER: 'cancelSubscriptionDrawer',
  SET_DEFAULT_PAYMENT_DRAWER: 'setDefaultPaymentDrawer',
  NON_PROFIT_DRAWER: 'nonProfitDrawer',
  EXEMPTION_CERTIFICATES_DRAWER: 'exemptionCertificatesDrawer',
  ADMIN_EXEMPTION_CERTIFICATES_DRAWER: 'adminExemptionCertificatesDrawer',
  PRINT_SELECT_PROJECT: 'printSelectProject',
  PRINT_PREVIEW: 'printPreviewDrawer',
  REVIEW: 'reviewDrawer',
  EDITOR_DRAWER: 'editorDrawer',
  PRINT_CHECKOUT_SHIPPING: 'printCheckoutShippingDrawer',
  SNAILBLAST_AUDIENCE: 'snailblastAudience',
  SNAILBLAST_UPLOAD_ADDRESS_EDIT: 'snailblastUploadAddressEdit',
  MANAGE_ADDRESSES: 'manageAddresses',
  MARGIN_OPTIONS: 'marginOptions',
  CONTRIBUTOR_CUSTOMER_PROJECT_NOTIFY_DRAWER: 'contributorCustomerProjectNotifyDrawer',
  ADD_COLLECTION_TEMPLATE: 'addCollectionTemplate',
  BLANK_CANVAS: 'blankCanvas',
  EDIT_COLLECTION_TEMPLATE: 'editCollectionTemplate',
  EDIT_COLLECTION_IMAGE: 'editCollectionImage',
  EDIT_PEOPLE: 'editPeople',
  EDIT_MASTER_ACCOUNT: 'editMasterAccount',
  ADD_PEOPLE: 'addPeople',
  EDIT_CAMPAIGN_IDEA: 'editCampaignIdea',
  EDIT_CAMPAIGN_IDEA_FILTERS: 'editCampaignIdeaFilters',
  EDIT_CAMPAIGN_IDEA_TEMPLATES: 'editCampaignIdeaTemplates',
  SELECT_CAMPAIGN_IDEA: 'selectCampaignIdea',
  ADMIN_INVOICE: 'adminInvoice',
  SHARE_PROJECT: 'shareProject',
  INVOICE_CHECKOUT: 'invoiceCheckout',
  ADD_COLLECTION: 'addCollection',
  ADD_COLLECTION_PROMO: 'addCollectionPromo',
  SELECT_PEOPLE: 'selectPeople',
  EDIT_FOLDER: 'editFolder',
  ADD_COLOR: 'addColor',
  UPLOAD_IMAGES: 'uploadImages',
  EDIT_ATTRIBUTE: 'editAttribute',
  RENAME: 'renameDrawer'
};

export const PRINT_CHECKOUT_SCREENS = {
  PROJECTS: 'printCheckoutProjects',
  CART: 'printCheckoutCart',
  EDIT: 'printCheckoutEdit',
  CHECKOUT: 'printCheckout',
  CONFIRMATION: 'printCheckoutConfirmation'
};

export const EXEMPTION_CERTIFICATE_VIEWS = {
  MANAGEMENT: 'management',
  DETAILS: 'details'
};

export const EXEMPTION_CERTIFICATE_STATUS = {
  APPROVED: 1,
  UNDER_REVIEW: 2,
  REJECTED: 3
};
export const APP_EVENTS = {
  APP_DATA_READY: 'appDataReady',
  IFRAMED_CATALOG_LOADED: 'iframedCatalogLoaded',
  REFRESH_PRINT_DRAWER: 'refreshPrintDrawer',
  REFRESH_PROJECT_DETAIL: 'refreshProjectDetail',
  REFRESH_SELECTED_PRINT_PROJECT: 'refreshSelectedPrintProject',
  OPEN_HELPSCOUT: 'openHelpscout',
  SET_NAV_VISIBILITY: 'setNavVisibility',
  SNACKBAR_MOUNTED: 'snackbarMounted',
  SHOW_PROOF_OVERLAY: 'showProofOverlay',
  SHOW_IMAGE_OVERLAY: 'showImageOverlay',
  GET_EDIT_ITEM_PRICE: 'getEditItemPrice',
  GET_LIST_PURCHASE_COUNT: 'getListPurchaseCount',
  TAX_EXEMPT_CERT_UPDATED: 'taxExemptCertUpdated',
  EDIT_ITEM_LOAD_DATA: 'editItemLoadData',
  SET_PAYMENT_METHOD: 'setPaymentMethod',
  REFRESH_MASTER_ACCOUNTS: 'refreshMasterAccounts',
  REFRESH_PEOPLE: 'refreshPeople',
  IDEA_FILTERS_SET: 'ideaFiltersSet',
  IDEA_TEMPLATES_SET: 'ideaTemplatesSet',
  PEOPLE_SELECTED: 'peopleSelected'
};

export const DESIGN_PROJECT_STATUS = {
  PENDING: 1,
  OPEN: 2,
  ASSIGNED: 3,
  UNDER_REVIEW: 4,
  COMPLETE: 5
};

export const ASSET_PROJECT_STATUS = {
  OPEN: 1,
  ASSIGNED: 2,
  UNDER_REVIEW: 3,
  PUBLISHED: 4
};

export const POSTMESSAGE_EVENTS = {
  CLOSE_IFRAMED_EDITOR: 'mcs_editor-close-iframed-editor',
  CREATE_PROJECT: 'create-project',
  IFRAMED_CATALOG_LOADED: 'embedded-catalog-loaded',
  IFRAMED_PRINT_DRAWER_OPENED: 'iframed-print-drawer-opened',
  IFRAMED_PRINT_PROJECT_APPROVED: 'iframed-print-project-approved',
  IFRAMED_PRINT_UPDATE_PREVIEW: 'iframed-print-update-preview',
  IFRAMED_SNAILBLAST_REVIEW_COMPLETE: 'iframed-snailblast-review-complete',
  OPEN_URL: 'mcs_editor-open-url'
};

export const ANALYTICS_EVENTS = {
  DRAWER_OPENED: 'drawer_opened',
  LIMITATION: {
    NAME: 'limitation'
    // can't use actions for this event because we want to mirror the limitation events in the editor
    // these events don't use actions, they pass a null action and use an object with a message property
  },
  PAYMENT: {
    NAME: 'dashboard_payment',
    ACTIONS: {
      SETUP_PAYMENT_START: 'setup_payment_start',
      SETUP_PAYMENT_SUCCESS: 'setup_payment_success',
      SETUP_PAYMENT_ERROR: 'setup_payment_error'
    }
  },
  SUBSCRIBE: {
    NAME: 'dashboard_subscribe',
    ACTIONS: {
      SUBSCRIBE_START: 'start',
      SUBSCRIBE_SUCCESS: 'success',
      SUBSCRIBE_SUCCESS_IFRAME: 'success_iframe',
      SUBSCRIBE_ERROR: 'error'
    }
  },
  PRINT: {
    NAME: 'dashboard_print',
    ACTIONS: {
      CART_ERROR: 'cart_error',
      ORDER_START: 'order_start',
      ORDER_SUCCESS: 'order_success',
      ORDER_ERROR: 'order_error'
    }
  },
  MAILING_SERVICE: {
    NAME: 'mailing_service',
    ACTIONS: {
      FIX_FREEDOM: 'fix_freedom',
      FIX_CHILI: 'fix_chili',
      NEW: 'new',
      EXISTING: 'existing',
      SAVE_FORMAT: 'save_format',
      SAVE_COMPLETE: 'save_complete',
      LIST_TYPE_SET: 'list_type_set',
      LOCATION_SET: 'location_set',
      FILTER_SET: 'filter_set',
      MAP_EXPAND: 'map_expand',
      ROUTE_SET: 'route_set'
    },
    SCREENS: {
      START: 'start',
      FORMAT: 'format',
      FORMAT_PREVIEW: 'format_preview',
      HELP_GUIDE: 'help_guide',
      UPLOAD_OVERVIEW: 'upload_overview',
      UPLOAD_VIEW_LIST: 'upload_view_list'
    },
    TYPE: { EDDM: 'eddm', UPLOAD: 'upload', LIST_BUILDER: 'list_builder' }
  },
  EDITOR_BLOCKED: {
    NAME: 'editor_blocked',
    ACTIONS: {
      SUBSCRIPTION_REQUIRED: 'subscription_required',
      SUBSCRIPTION_CANCELED: 'subscription_canceled',
      SUBSCRIPTION_PASTDUE: 'subscription_pastdue',
      ACCESS_DENIED: 'access_denied' // should hopefully never see this one
    }
  },
  TEAMS: {
    NAME: 'teams',
    ACTIONS: {
      INVITE_DRAWER_OPENED: 'invite_drawer_opened',
      INVITE_ERROR: 'invite_error'
    }
  }
};

export const ENUMS = enums;

export const ALERT_TYPES = {
  ALERT: 'alert',
  SUCCESS: 'success',
  ERROR: 'error',
  HELP: 'help',
  INFO: 'info'
};

export const LINKS = {
  SNAILBLAST_TEMPLATE:
    'https://res.cloudinary.com/snail-blast/raw/upload/v1686860919/Resources/Spray-Net_Upload_Full_re8kcp.csv'
};
