import { cloneDeep, sumBy } from 'lodash';

const state = {
  list: [],
  user: {},
  orders: []
};

const getters = {
  list(state) {
    return state.list || [];
  },
  user(state) {
    return state.user || {};
  },
  orders(state) {
    return state.orders || [];
  },
  orders_total(state, getters) {
    return sumBy(getters.orders, 'total');
  }
};

const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  APPEND_LIST(state, list) {
    for (var item of list) {
      state.list.push(item);
    }
  },
  SET_ORDERS(state, list) {
    state.orders = list;
  },
  SET_USER(state, user) {
    state.user = cloneDeep(user);
    const index = state.list.findIndex((x) => x.id === user.id);
    if (index >= 0) {
      this._vm.$set(state.list, index, cloneDeep(user));
    } else {
      state.list.push(cloneDeep(user));
    }
  },
  DELETE_USER(state, id) {
    const index = state.list.findIndex((x) => x.id === id);
    if (index >= 0) {
      state.list.splice(index, 1);
    }
  }
};

const actions = {
  async getList({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('users/getList', req)
        .then((response) => {
          if (req.append) {
            commit('APPEND_LIST', response.data.list);
          } else {
            commit('SET_LIST', response.data.list);
          }
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async get({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`users/get?id=${id}`)
        .then((response) => {
          commit('SET_USER', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getOrders({ commit }, userId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`users/getOrders?userId=${userId}`)
        .then((response) => {
          commit('SET_ORDERS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async save({ commit }, user) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('users/save', user)
        .then((response) => {
          commit('SET_USER', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async remove({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .put(`users/remove?id=${id}`)
        .then((response) => {
          commit('DELETE_USER', id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async removeFromTeam({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .put(`users/removeFromTeam?id=${req.id}&collectionId=${req.collectionId}`)
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`users/delete?userId=${id}`)
        .then((response) => {
          commit('DELETE_USER', id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async clearList({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_LIST', []);
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
