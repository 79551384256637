<template>
  <v-system-bar
    v-if="render"
    :app="app ? true : false"
    height="48"
    class="px-0"
  >
    <SubscriptionPastDue v-if="showSubscriptionPastDue" />
    <PrintPromo v-else-if="showPrintPromo" />
  </v-system-bar>
</template>

<script>
import PrintPromo from '../alerts/PrintPromo';
import SubscriptionPastDue from '../alerts/SubscriptionPastDue';

export default {
  name: 'AlertBar',

  components: {
    PrintPromo,
    SubscriptionPastDue
  },

  props: {
    app: Boolean
  },

  data() {
    return {};
  },

  computed: {
    render() {
      if (!this.$store.getters['user/isMCS']) return false;
      if (this.$store.state.config.disableAlertBar) return false;
      return this.showSubscriptionPastDue || this.showPrintPromo;
    },

    showSubscriptionPastDue() {
      return this.$store.state.user.plan.subscriptionIsPastDue;
    },

    showPrintPromo() {
      if (this.$store.state.config.route.meta?.iframe) {
        var item = this.$store.getters['printcart/edit_item'];
        if (item.project && item.project.category?.toLowerCase() === 'yard sign') {
          return false;
        }
      }
      if (this.$store.getters['user/isAdmin'] || this.$store.getters['user/isMasterAccountOwner']) {
        return false;
      }
      return !this.$store.state.user.hasPrinted;
    }
  },

  methods: {}
};
</script>

<style></style>
