<template>
  <div>
    <template v-if="cards.length > 1 && showCards">
      <v-select
        v-model="selectedCardId"
        :items="cards"
        item-value="id"
        item-text="display"
        hide-details
        outlined
        label="Select Payment Method"
        persistent-placeholder
      />
      <div
        v-if="selectedCardId !== 0"
        class="caption mt-2"
      >
        <v-icon small>mdi-lock</v-icon>
        Payments are 128-bit SSL encrypted so they're 100% safe with us.
      </div>
    </template>
    <v-form :style="{ visibility: selectedCardId === 0 ? 'visible' : 'hidden' }">
      <template v-if="accountRequired">
        <v-form
          ref="accountForm"
          class="pa-4 grey border border-grey rounded-lg"
        >
          <div class="body-2 font-weight-medium mb-2">Account Details</div>
          <v-text-field
            v-model="account.username"
            :rules="[$rules.required, $rules.email]"
            maxlength="50"
            hide-details="auto"
            filled
            label="Username (email)"
            class="text-field-custom mb-5 body-1"
          />
          <v-text-field
            v-model="account.password"
            :rules="[$rules.required, $rules.minlength(account.password, 6)]"
            hide-details="auto"
            filled
            type="password"
            label="Password"
            class="text-field-custom body-1"
          />
        </v-form>
      </template>
      <div
        id="payment-element"
        class="my-5"
      />
      <div class="caption mt-2">
        <v-icon small>mdi-lock</v-icon>
        Payments are 128-bit SSL encrypted so they're 100% safe with us.
      </div>
    </v-form>

    <Portal
      :disabled="!portalButtonTo"
      :to="portalButtonTo"
    >
      <mcs-btn
        v-if="elementsReady"
        block
        large
        color="black"
        :loading="saving"
        @click="save"
      >
        {{ buttonText }}
      </mcs-btn>
      <mcs-alert
        v-show="error"
        type="error"
        class="mt-3"
        :text="error"
      />
    </Portal>
  </div>
</template>

<script>
import { ANALYTICS_EVENTS } from '../../utils/constants';

export default {
  name: 'PaymentEntryForm',

  props: {
    mode: {
      type: String,
      default: 'setup' // values: setup, subscription
    },
    returnUrl: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: 'Save'
    },
    amount: {
      type: Number,
      default: 0
    },
    stripePlanId: {
      type: String,
      default: ''
    },
    teamUserCount: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      default: 'usd'
    },
    promoCode: {
      type: String,
      default: ''
    },
    portalButtonTo: {
      type: String,
      default: ''
    },
    showCards: Boolean
  },

  data() {
    return {
      name: '',
      stripe: null,
      saving: false,
      elementsReady: false,
      selectedCardId: 0,
      account: {
        username: '',
        password: ''
      },
      error: ''
    };
  },

  computed: {
    cards() {
      return this.showCards ? this.$store.getters['cards/selectListWithAdd'] : [];
    },

    accountRequired() {
      return this.mode === 'subscription' && this.$store.state.user.plan.isGuest;
    }
  },

  mounted() {
    this.selectedCardId = this.cards.length > 1 ? this.cards[1].id : 0;
    var params = {};
    if (this.$store.state.user.masterAccount.stripeAccountId) {
      params.stripeAccount = this.$store.state.user.masterAccount.stripeAccountId;
    }
    this.stripe = window.Stripe(this.$store.state.config.stripePublicKey, params);
    const options = {
      mode: this.mode,
      currency: this.currency.toLowerCase(),
      appearance: {
        theme: 'stripe',
        labels: 'floating',
        variables: {
          fontFamily: 'Poppins,sans-serif',
          colorTextPlaceholder: '#000000'
        },
        rules: {
          '.Label--resting': {
            color: '#858585'
          },
          '.Label--floating': {
            fontSize: '13.5px'
          },
          '.Error': {
            fontSize: '12px',
            padding: '0 12px',
            color: this.$vuetify.theme.themes.light.error
          }
        }
      }
    };
    if (this.amount) options.amount = this.amount;
    this.elements = this.stripe.elements(options);
    const paymentElement = this.elements.create('payment', { terms: { card: 'never' } });
    paymentElement.mount('#payment-element');
    setTimeout(() => {
      this.elementsReady = true;
    }, 2000);
  },

  methods: {
    async save() {
      // Trigger form validation and wallet collection
      this.error = '';
      if (this.accountRequired) {
        if (!this.$refs.accountForm.validate()) return;
        this.createAccount();
        return;
      }
      if (this.mode === 'subscription') {
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.SUBSCRIBE.NAME,
          ANALYTICS_EVENTS.SUBSCRIBE.ACTIONS.SUBSCRIBE_START
        );
      } else if (this.mode === 'setup') {
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.PAYMENT.NAME,
          ANALYTICS_EVENTS.PAYMENT.ACTIONS.SETUP_PAYMENT_START
        );
      }
      try {
        this.saving = true;

        if (!this.selectedCardId) {
          const { error: submitError } = await this.elements.submit();
          if (submitError) {
            this.handleError(submitError);
            return;
          }
        }

        if (this.mode === 'setup') {
          await this.handleSetupIntent();
        } else if (this.mode === 'subscription') {
          await this.handleSubscribeIntent();
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        setTimeout(() => {
          // a little delay here because sometimes we have actions running outside of this handler before it's complete
          // i.e. update all cards after a delete, etc.
          this.saving = false;
        }, 4000);
      }
    },

    async handleSetupIntent() {
      // Create the SetupIntent and obtain clientSecret
      const res = await this.$api.post('cards/createIntent');
      var intentResult = await res.data;
      if (!intentResult.clientSecret) {
        this.handleError(new Error(intentResult.message));
        return;
      }

      // Confirm the SetupIntent using the details collected by the Payment Element
      const { error } = await this.stripe.confirmSetup({
        elements: this.elements,
        clientSecret: intentResult.clientSecret,
        redirect: 'if_required',
        confirmParams: {
          return_url: this.returnUrl
        }
      });

      if (error) {
        // This point is only reached if there's an immediate error when
        // confirming the setup. Show the error to your customer (for example, payment details incomplete)
        this.handleError(error);
      } else {
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        this.$emit('success');
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.PAYMENT.NAME,
          ANALYTICS_EVENTS.PAYMENT.ACTIONS.SETUP_PAYMENT_SUCCESS
        );
      }
    },

    async handleSubscribeIntent() {
      // Create the PaymentIntent
      const res = await this.$api.post('subscription/create', {
        stripePlanId: this.stripePlanId,
        cardId: this.selectedCardId === 0 || !this.selectedCardId ? '' : this.selectedCardId,
        promoCode: this.promoCode,
        teamUserCount: this.teamUserCount
      });
      var intentResult = await res.data;
      if (!intentResult.clientSecret) {
        this.handleError(new Error(intentResult.message));
        return;
      }

      // Confirm the PaymentIntent using the details collected by the Payment Element
      var confirmOpts = {
        clientSecret: intentResult.clientSecret,
        redirect: 'if_required',
        confirmParams: {
          return_url: 'https://dashboard.mycreativeshop.com/subscription'
        }
      };
      if (!this.selectedCardId) confirmOpts.elements = this.elements;
      const { error } = await this.stripe.confirmPayment(confirmOpts);

      if (error) {
        // This point is only reached if there's an immediate error when
        // confirming the payment. Show the error to your customer (for example, payment details incomplete)
        this.handleError(error);
      } else {
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        this.$emit('success');
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.SUBSCRIBE.NAME,
          ANALYTICS_EVENTS.SUBSCRIBE.ACTIONS.SUBSCRIBE_SUCCESS
        );
      }
    },

    handleError(error) {
      this.error = error.message;
      this.saving = false;
      if (this.mode === 'subscription') {
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.SUBSCRIBE.NAME,
          ANALYTICS_EVENTS.SUBSCRIBE.ACTIONS.SUBSCRIBE_ERROR,
          { error: error.message }
        );
      } else if (this.mode === 'setup') {
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.PAYMENT.NAME,
          ANALYTICS_EVENTS.PAYMENT.ACTIONS.SETUP_PAYMENT_SUCCESS,
          { error: error.message }
        );
      }
    },

    async createAccount() {
      this.saving = true;
      var payload = {
        username: this.account.username,
        password: this.account.password
      };
      this.$store
        .dispatch('user/createFromGuest', payload)
        .then(() => {
          this.$emit('accountCreated', payload);
          this.save();
        })
        .catch((error) => {
          this.handleError(error);
        });
    }
  }
};
</script>

<style scoped>
.text-field-custom >>> .v-input__slot {
  background-color: white !important;
  border: solid 1px #e6e6e6;
  border-radius: 4px;
  overflow: hidden;
  color: black;
}
</style>
